import { NgModule, Injectable } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { WindowUtil } from "./shared/utils/window.util";
import {
  ToasterService,
  MessageType,
} from "projects/shared-lib/src/public-api";
import { LocalStorageUtil } from "./shared/utils/local-storage.util";

@NgModule({
  providers: [WindowUtil],
})
export class AppDisableBrowserBtnsModule {
  private window: Window;
  private scrollX = 0;
  private scrollY = 0;
  private userTypeName: string;

  constructor(
    private router: Router,
    private windowUtil: WindowUtil,
    private localStorageUtil: LocalStorageUtil,
    private toasterService: ToasterService,
    private activatedRoute: ActivatedRoute
  ) {
    this.window = this.windowUtil.windowScope;
    // Removed disabling back button
    // this.disableBackButton();
    // this.addPopStateEventListener();
    this.userTypeName= this.activatedRoute.snapshot.params["category"];
  }

  private getUrl(): string {
    const language = this.localStorageUtil.getLanguage(this.userTypeName);
    const splitUrl = location.pathname.split("?");
    return `${splitUrl[0]}?lang=${language}`;
  }

  private addPopStateEventListener(): void {
    this.window.addEventListener("popstate", () => {
      this.toasterService.show(
        MessageType.INFORMATIONAL,
        "This functionality has been disabled"
      );
      this.getScrollCoordinates();
      const url = this.getUrl();
      this.window.history.pushState(null, null, url);
      setTimeout(this.scrollToThePreviousPosition.bind(this));
    });
  }

  private scrollToThePreviousPosition(): void {
    this.window.scrollTo(this.scrollX, this.scrollY);
  }

  private getScrollCoordinates(): void {
    this.scrollX = this.window.scrollX;
    this.scrollY = this.window.scrollY;
  }

  private disableBackButton(): void {
    this.window.history.pushState(null, null, null);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.window.history.pushState(null, null, null);
      }
    });
  }
}
